import * as React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Billboard from '../components/billboard'

const aboutPage = (props) => (
  <Layout location={props.location}>
        <Seo title="PRESS" keywords={[`XANDOR`, `Xandor`, `Xandor Articles`, `Xandor Press`, `Xandor Music`,`Xandor EDM`,`Xandor Artist`,`Xandor Musical Artist`,`Xandor Instagram`,`Xandor Spotify`,`Xandor Official website`,`Xandor Soundcloud`, `Xandor DJ`, `Xandor Boston`, `Xandor Xicay`, `Xandormusic`, `Xandor electronic music`]} description="XANDOR Recent Articles and News."/>
    <Billboard image={props.data.heroImage.childImageSharp.fluid}/>
    <Container>
      <section className="press" id="press">
          <Row>
            <Col lg="12">
              <h1 className="text-center  mt-3 mb-5">Press</h1>
            </Col>
            <Col>
              <div className="article">
                <h2><a href="https://www.youredm.com/2022/02/11/xandor-releases-glistening-music-video-to-accompany-deep-house-single-alive/">Xandor Releases Glistening Music Video To Accompany Deep House Single “Alive”</a></h2>
                <p>Boston native <strong>XANDOR</strong> released a glistening music video to accompany his sensational deep house single “Alive“, that whisks you away into a breathtaking, west coast adventure for an entire 4 minutes. It’s a blissful romance between two loving individuals...<br/> <span className="text-muted">YOUREDM - February 11, 2022</span> </p>
              </div>

              <div className="article">
                <h2><a href="https://dancingastronaut.com/2022/02/xandor-sets-out-to-make-streamers-feel-alive-in-first-single-of-2022/">XANDOR sets out to make streamers feel ‘ALIVE’ with first single of 2022</a></h2>
                <p>For a West Coast escape from the daily quotidian, look no further than <strong>XANDOR's</strong> “ALIVE.” Written during the COVID-19 quarantine alongside Clara Hurtado, the dance-pop-driven single—XANDOR’s first of the new calendar year—conveys the message “that we are alive, and that’s a gift we shouldn’t take for granted...<br/> <span className="text-muted">Dancing Astronaut - February 11, 2022</span> </p>
              </div>

              <div className="article">
                <h2><a href="https://www.cultr.com/news/xandor-alive/">Xandor Drops Stunning Music Video For Deep House Single ‘Alive’</a></h2>
                <p><strong>XANDOR</strong> Boasting a video that grasps your attention from the very first moments, “Alive,” stuns the brain with an emotional melody and message on the music side plus a captivating story on the visual side...<br/> <span className="text-muted">CULTR - February 8, 2022</span> </p>
              </div>

              <div className="article">
                <h2><a href="https://www.ravejungle.com/2022/02/08/xandor-alive-music-video/">XANDOR unveils cinematic music video for top-class deep house single ‘Alive’</a></h2>
                <p><strong>Alive</strong>, fuses together several gripping, cinematic aspects all at once thanks to the vision of Xandor and the team he’s surrounded himself with. The moment capturing music video features a blissful romance between two loving individuals, with overflowing beauty being beamed to viewers within every shot from start to finish.... <br/> <span className="text-muted">Rave Jungle - February 8, 2022</span> </p>
              </div>

              <div className="article">
                <h5><a href="https://www.edmtunes.com/2020/09/xandor-teams-up-with-vocalist-katie-dicicco-to-bring-us-a-pop-heavy-deep-house-tune-in-insatiable/">XANDOR Teams Up With Vocalist Katie Dicicco To Bring Us A Pop Heavy Deep House Tune in Insatiable</a></h5>
                <p>Making his EDMTunes debut is deep house maestro, <strong>XANDOR</strong>, and he’s bringing vocalist Katie Dicicco along for the ride. <strong>XANDOR</strong> is a DJ/Record Producer, who has come to be known for his take on the future deep house genre. His music contains silky, sensual soundscapes... <br/> <span className="text-muted">EDMTunes - September 25, 2020</span> </p>
              </div>

              <div className="article">
                <h5><a href="https://www.exclusiveaudio.net/blog/xandorinsatiable">Xandor Drops EDM Pop Track ‘Insatiable’ ft. Katie DiCicco</a></h5>
                <p><strong>XANDOR</strong> (@Xandor), an upcoming producer and DJ igniting the current and future deep house music scene with his silky, sensual soundscapes is back with his new hit “Insatiable” featuring talented singer/songwriter Katie DiCicco.  Stream ‘Insatiable’ on Spotify... <br/> <span className="text-muted">Exclusive Audio - September 18, 2020</span> </p>
              </div>

              <div className="article">
                <h5><a href="https://www.staccatofy.com/electronica/xandor-insatiable-review/">XANDOR, Insatiable Review</a></h5>
                <p><strong>XANDOR</strong>, is a producer and DJ igniting the deep house music scene with his sleek, erotic sound tapestries. The son of a renowned saxophone player, music is a core part of XANDOR’s life. He was inspired to make electronic music after attending a... <br/> <span className="text-muted">Staccatofy - September 02, 2020</span> </p>
              </div>

              <div className="article">
                <h5><a href="http://poponandon.com/2020/10/22/premiere-xandor-insatiable-ft-katie-dicicco/">Premiere: XANDOR – Insatiable (ft. Katie DiCicco)</a></h5>
                <p>Today, we’re sharing one of the sexiest music videos we’ve seen around in a while called “Insatiable”, produced by the talented newcomer XANDOR. Fitting for its name, there’s something extremely seductive about the way that Katie DiCicco sings over this track, all the while having somewhat of a darker vibe.... <br/> <span className="text-muted">Pop On And On - October 22, 2020</span> </p>
              </div>

              <div className="article">
                <h5><a href="https://ventsmagazine.com/2020/10/23/xandor-teams-up-with-katie-dicicco-for-a-vibrant-and-sultry-insatiable-music-video/">XANDOR teams up with Katie DiCicco for a vibrant and sultry “Insatiable” music video</a></h5>
                <p><strong>XANDOR</strong> releases the official music video for his latest pop-infused single, “Insatiable”, featuring the multi-talented singer-songwriter and vocalist Katie DiCicco. The transcendent pop-infused future deep house track is a celebration of the very definition of “insatiable”...<br/> <span className="text-muted">Vents Magazine - October 23, 2020</span> </p>
              </div>

              <div className="article">
                <h5><a href="https://wefoundnewmusic.com/xandor-insatiable-music-video/">XANDOR - Insatiable [Music Video]</a></h5>
                <p><strong>XANDOR</strong>, treleases the official music video for his latest pop-infused single, “Insatiable”, featuring the multi-talented singer-songwriter and vocalist Katie DiCicco. The transcendent pop-infused future deep house track is a celebration of the very definition of “insatiable”: an appetite or desire that is impossible to satisfy and sharing an intimate connection with someone; a part of love and the human experience... <br/> <span className="text-muted">WE FOUND NEW MUSIC - November 04, 2020</span> </p>
              </div>

              <div className="article">
                <h5><a href="https://www.nagamag.com/the-latest/xandor-insatiable-ft-katie-dicicco-video/">XANDOR – Insatiable ft. Katie DiCicco (Video)</a></h5>
                <p>The transcendent pop-infused future deep house track is a celebration of the very definition of “insatiable”: an appetite or desire that is impossible to satisfy and sharing an intimate connection with someone; a part of love and the human experience... <br/> <span className="text-muted">Nagamag - November 10, 2020</span> </p>
              </div>

              <div className="article">
                <h5><a href="https://purplemelonmu.com/2020/11/15/xandor-releases-insatiable-the-sultry-club-banger-ft-katie-dicoco/">XANDOR RELEASES ‘INSATIABLE’, THE SULTRY CLUB BANGER FT. KATIE DICICCO</a></h5>
                <p><strong>XANDOR</strong>, the dynamic future deep-house producer and DJ has been on the scene since 2018 wowing crowds in and around Boston, Massachusetts with his silky, sensual soundscapes. With his debut album on the way, the young millennial hopes to take his ambitions up a notch... <br/> <span className="text-muted">Purple Melon - November 25, 2020</span> </p>
              </div>
            </Col>
          </Row>
        </section>
    </Container>
  </Layout>
)

export default aboutPage

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 536, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`;